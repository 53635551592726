import 'jsonapi-parse'
import axios from 'axios'
import { camelCaseKeys, correctIdDataTypes } from '@utils'

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any> (config: AxiosRequestConfig): Promise<T>
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  }
}

const baseApiUrl = '/api'
const axiosInstance = axios.create()

const metaCsrfEl = document.querySelector('meta[name="csrf-token"]')
if (!metaCsrfEl) {
  throw new Error('No CSRF token found')
}

export const instanceDefaults = {
  baseURL: baseApiUrl,
  commonHeaders: [
    {
      key: 'X-Requested-With',
      value: 'XMLHttpRequest',
    },
    {
      key: 'X-CSRF-Token',
      value: metaCsrfEl.getAttribute('content') as string
    }
  ]
}

axiosInstance.defaults.baseURL = instanceDefaults.baseURL
instanceDefaults.commonHeaders.forEach((commonHeader) => {
  axiosInstance.defaults.headers.common[commonHeader.key] = commonHeader.value
})

axiosInstance.interceptors.response.use(
  resp => {
    // NOTE: The deserializer does not correctly cast the `id` column to integer
    // This causes ID matching === elsewhere to fail. For some reason, TS doesn't
    // perform an implicit cast and fix this even though the columns in the models
    // are listed as `number`. This helper method was my solution for now.
    return correctIdDataTypes(
      camelCaseKeys(
        resp.data.__no_jsonapi_parse__ ? resp.data : window.jsonapi.parse(resp.data))
    )
  },
)

export default axiosInstance