import { reduce } from 'lodash-es'
import AppContext from '@utils/context'
import type ReportEntry from '@models/ReportEntry'

interface TaskColumn {
  col: number
  label: string
}

interface TaskColumnConfiguration {
  columns: {
    [key: string]: TaskColumn
  }
  totalCols: number
}

export function columnConfiguration (task: ReportEntry) {
  if (task.completed) {
    return completedColumns()
  } else if (task.dueNow) {
    return dueNowColumns()
  } else if (task.upcoming) {
    return upcomingColumns()
  } else {
    console.warn('DEFAULT TASK COLUMN CONFIGURATION SELECTED', task)
    return dueNowColumns()
  }
}

export function dueNowColumns (): TaskColumnConfiguration {
  const result = {
    task: { col: 2, label: 'Task' },
    ...AppContext.isChurchAdminOrOwner && { assignee: { col: 1, label: 'Assignee' } },
    frequency: { col: 1, label: 'Frequency' },
    due: { col: 1, label: 'Due' },
    input: { col: 2, label: 'Input' },
  }
  return {
    columns: result,
    totalCols: reduce(result, (acc, opt) => { acc += opt ? opt.col : 0; return acc }, 0),
  }
}

export function upcomingColumns (): TaskColumnConfiguration {
  const result = {
    task: { col: 2, label: 'Task' },
    ...AppContext.isChurchAdminOrOwner && { assignee: { col: 1, label: 'Assignee' } },
    frequency: { col: 1, label: 'Frequency' },
    due: { col: 1, label: 'Due' },
  }
  return {
    columns: result,
    totalCols: reduce(result, (acc, opt) => { acc += opt ? opt.col : 0; return acc }, 0),
  }
}

export function completedColumns (): TaskColumnConfiguration {
  const result = {
    task: { col: 2, label: 'Task' },
    value: { col: 1, label: 'Number' },
    frequency: { col: 1, label: 'Frequency' },
    completed: { col: 1, label: 'Completed' },
  }
  return {
    columns: result,
    totalCols: reduce(result, (acc, opt) => { acc += opt ? opt.col : 0; return acc }, 0),
  }
}