import { DateTime } from 'luxon'

import ReportFormMetric from '@models/ReportFormMetric'
import type { IReportFormSection,IReportFormMetric } from '@models/interfaces'

class ReportFormSection {
  id: number
  sectionType: string
  commonName: string
  customName?: string
  dayOfWeek?: string
  inactiveAt?: string
  inactiveAt_lux?: DateTime
  
  serviceType?: 'weekly' | 'scheduled'
  scheduledWeeks?: string[]
  isScheduleComplete?: boolean
  scheduleCompletedAt?: string
  scheduleCompletedAt_lux?: DateTime

  reportFormId: number
  inactiveById?: number

  reportFormMetrics: ReportFormMetric[]

  constructor (data: IReportFormSection) {
    Object.assign(this, data)
    this.inactiveAt_lux = this.inactiveAt ? DateTime.fromISO(this.inactiveAt) : undefined
    this.scheduleCompletedAt_lux = this.scheduleCompletedAt ? DateTime.fromISO(this.scheduleCompletedAt) : undefined

    if (this.reportFormMetrics && this.reportFormMetrics.length > 0) {
      // TODO: Do something about this type casting message???
      this.reportFormMetrics = this.reportFormMetrics.map(d => new ReportFormMetric(d as IReportFormMetric))
    }
  }

  get displayName () {
    return this.customName || this.commonName
  }

  get isService () {
    return this.sectionType === 'service'
  }

  get isGeneral () {
    return this.sectionType === 'general'
  }

  get isScheduled () {
    return this.serviceType === 'scheduled'
  }

  get canEditName () {
    return this.isService
  }

  get canCollapse () {
    return this.isService
  }
}

export default ReportFormSection