import { ref } from 'vue'

import { fetchMetricsForReportFormSection } from '@http/ReportFormSections'
import ReportFormMetric from '@models/ReportFormMetric'
import AppContext from '@utils/context'

import type { Ref } from 'vue'
import type { IReportFormMetric } from '@models/interfaces'

export interface IMetricDefinition {
  label: string
  slug: string
  format: 'number' | 'currency'
  accessor: string
}

type AfterFetchCallback = (metrics: Array<IMetricDefinition>) => void

class MetricFetcher {
  private _reportFormSectionId?: number
  private _metricCache: Ref<Array<IMetricDefinition>> = ref([])
  private _afterFetchCbs: Array<AfterFetchCallback> = []

  /**
   * Sets the ReportFormSection ID to use to request metrics.
   * This setter will automatically refetch the metrics.
   */
  public set reportFormSectionId (id: number | undefined) {
    console.log(this)
    this._reportFormSectionId = id
    this.fetchMetrics()
  }

  /**
   * Returns a Vue Ref that contains the metric data returned
   * from the API.
   */
  public get metricsRef () {
    return this._metricCache
  }

  /**
   * Returns the metric data returned from the API without
   * a Vue Ref.
   */
  public get metrics () {
    return this.metricsRef.value
  }

  /**
   * Dynamically selects the best underlying method for fetching
   * metrics. This method can be extended in the future to support
   * more fetching modes.
   * - By default, this method will call `_fetchDefaultMetrics`
   * - Setting a `reportFormSectionId` will cause the method to fetch
   *   metrics from a specific service
   */
  public async fetchMetrics () {
    let fn = this._fetchDefaultMetrics
    if (this._reportFormSectionId) {
      fn = this._fetchMetricsFromReportFormSection
    }
    this._metricCache.value = await fn.bind(this)()
    this._afterFetchCbs.forEach(fn => fn(this.metrics))
  }

  /**
   * Registers a callback to be executed after new metrics are fetched.
   * Callbacks are executed in the order they are registered.
   * @param fn Callback function to be executed
   */
  public afterFetch (fn: AfterFetchCallback) {
    this._afterFetchCbs.push(fn)
  }

  /**
   * Fetches the metrics for a specific ReportFormSection. If no
   * reportFormSectionId is set on this class, this method will return
   * an empty array with no HTTP request. Otherwise an HTTP request
   * is fired and an array of metrics are returned.
   */
  private async _fetchMetricsFromReportFormSection (): Promise<Array<IMetricDefinition>> {
    if (!this._reportFormSectionId) {
      return []
    }
    const resp = await fetchMetricsForReportFormSection(this._reportFormSectionId)
    return resp.map((m: IReportFormMetric) => {
      const metric = new ReportFormMetric(m)
      return {
        label: metric.displayName,
        slug: metric.metricSlug,
        format: metric.fieldType,
        accessor: metric.accessor,
      } as IMetricDefinition
    })
  }

  /**
   * Uses the metrics that are baked into the page in AppContext.
   * These metrics are the ones that come from `ApplicationController#metrics`.
   * No HTTP request is made for this method, but remains an async method
   * so it adheres to the common interface used in `fetchMetrics`.
   */
  private async _fetchDefaultMetrics (): Promise<Array<IMetricDefinition>> {
    return AppContext.metricConfigs.map((m: IIndexable) => {
      return {
        label: m.metricName,
        slug: m.metricSlug,
        format: m.format,
        accessor: m.accessor,
      } as IMetricDefinition
    })
  }
}

export default MetricFetcher