import type { IReportEntryAssignee } from '@models/interfaces'

class ReportEntryAssignee {
  id: number
  reportEntryId: number
  churchSiteId: number
  assigneeId: number
  assignedById: number
  sourceReportFormMetricAssigneeId: number

  assigneeFirstName: string
  assigneeLastName: string

  assigneePhone: string
  assigneeEmail: string

  constructor (data: IReportEntryAssignee) {
    Object.assign(this, data)
  }

  get name () {
    return `${this.assigneeFirstName} ${this.assigneeLastName}`
  }
}

export default ReportEntryAssignee