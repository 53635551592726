import { Controller } from '@hotwired/stimulus'
import { formatCurrency, formatNumber } from '@utils'

export default class extends Controller {
  // @ts-ignore
  declare readonly element: HTMLElement
  lazyThresholdValue: number
  lazyRootMarginValue: string
  startValue: number
  endValue: number
  durationValue: number
  lazyValue: number

  static values = {
    start: Number,
    end: Number,
    duration: Number,
    format: String,
    lazyThreshold: Number,
    lazyRootMargin: {
      type: String,
      default: '0px',
    },
    lazy: Boolean,
  }

  connect (): void {
    this.lazyValue ? this.lazyAnimate() : this.animate()
  }

  animate (): void {
    let startTimestamp: number|null = null

    const startValue: number = (this.data.get('startValue') || 0) as number
    const endValue: number = (this.data.get('endValue') || 0) as number
    const durationValue: number = (this.data.get('durationValue') || 0) as number
    const numberFormat: string = (this.data.get('format') || 'default') as string

    const step = (timestamp: number) => {
      if (!startTimestamp) {
        startTimestamp = timestamp
      }

      const elapsed: number = timestamp - startTimestamp
      const progress: number = Math.min(elapsed / durationValue, 1)
      const result = Math.floor(progress * (endValue - startValue) + Number(startValue))
      this.element.innerHTML = numberFormat === 'currency'
        ? formatCurrency(result)
        : formatNumber(result)

      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    }

    window.requestAnimationFrame(step)
  }

  lazyAnimate (): void {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          this.animate()
          observer.unobserve(entry.target)
        }
      })
    }, this.lazyAnimateOptions)

    observer.observe(this.element)
  }

  get lazyAnimateOptions (): IntersectionObserverInit {
    return {
      threshold: this.lazyThresholdValue,
      rootMargin: this.lazyRootMarginValue,
    }
  }
}