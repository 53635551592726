import BaseModel from '@models/BaseModel'

import type { IRegion } from '@models/interfaces'

class Region extends BaseModel {
  NAME = 'Region'

  id: number
  name: string

  constructor (data: IRegion) {
    super(data)
    Object.assign(this, data)
  }
}

export default Region