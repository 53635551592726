import axios from '@services/axios'
import ChurchSite from '@models/ChurchSite'
import type { IChurchSite } from '@models/interfaces'

export interface IFetchChurchSitesParams {
  church_id: number
}

export async function fetchChurchSites (params: IFetchChurchSitesParams) {
  const response = await axios.get(`/churches/${params.church_id}/church_sites.json`)
  return response.data.map((c: IChurchSite) => (new ChurchSite(c)))
}