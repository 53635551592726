import type { IReportFormMetricAssignee } from '@models/interfaces'

class ReportFormMetricAssignee {
  id: number
  assigneeFirstName: string
  assigneeLastName: string
  assigneeEmail: string

  assigneeId: number
  assignedById: number
  reportFormId: number
  reportFormMetricId: number

  constructor (data: IReportFormMetricAssignee) {
    Object.assign(this, data)
  }

  get name () {
    return `${this.assigneeFirstName} ${this.assigneeLastName}`
  }
}

export default ReportFormMetricAssignee