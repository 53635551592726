import ReportFormMetricAssignee from '@models/ReportFormMetricAssignee'
import AppContext from '@utils/context'

import type { IReportFormMetric, IReportFormMetricAssignee } from '@models/interfaces'

class ReportFormMetric {
  id: number
  metricSlug?: string
  metricName: string
  metricDescription: string | null
  frequency: string
  fieldType: string
  isCustom: boolean

  reportFormId: number
  reportFormSectionId: number
  reportFormSectionType: string

  reportFormMetricAssignee: ReportFormMetricAssignee | null

  constructor (data: IReportFormMetric) {
    Object.assign(this, data)
    if (this.reportFormMetricAssignee) {
      this.reportFormMetricAssignee = new ReportFormMetricAssignee(this.reportFormMetricAssignee as IReportFormMetricAssignee)
    }
  }

  get displayName () {
    return this.metricName
  }

  get isOnService () {
    return this.reportFormSectionType === 'service'
  }

  get isOnGeneral () {
    return this.reportFormSectionType === 'general'
  }

  get isWeekly () { return this.frequency === 'weekly' }
  get isMonthly () { return this.frequency === 'monthly' }
  get isQuarterly () { return this.frequency === 'quarterly' }
  get isAnnually () { return this.frequency === 'annually' }

  get canEditName () {
    return true // this.isCustom
  }

  get canEditFrequency () {
    return !this.isOnService
  }

  get canEditMetricSlug () {
    return true
  }

  get accessor () {
    if (AppContext.viewingChurch || AppContext.viewingChurchSite) {
      return this.displayName
    } else {
      return this.metricSlug
    }
  }
}

export default ReportFormMetric