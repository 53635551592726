class BaseModel {
  declare readonly NAME: string

  constructor (data: any) {}

  get className () {
    return this.NAME
  }
}

export default BaseModel