import mitt from 'mitt'

type Events = {
  event: string
  data?: any
}

// BEEP BEEP
const BUS = mitt<Events>()

export function subscribe (evt: any, cb: any) {
  BUS.on(evt, cb)
  return () => BUS.off(evt, cb)
}

export function dispatch (evt: any, params?: any) {
  return BUS.emit(evt, params)
}

export default { subscribe, dispatch }

// Search for buttons across the page that are set to
// trigger an event dispatch.
document.querySelectorAll('[data-event-dispatch]').forEach(el => {
  const elAttrs = el.attributes as IIndexable
  const eventName = elAttrs['data-event-dispatch'].value
  const eventData = JSON.parse(elAttrs['data-event-data'].value)
  const eventOn = elAttrs['data-event-on']?.value || 'click'
  el.addEventListener(eventOn, () =>{
    dispatch(eventName, eventData)
  })
})