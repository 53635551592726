import BaseModel from '@models/BaseModel'

import type { IUser } from '@models/interfaces'

class User extends BaseModel {
  NAME = 'User'

  id: number
  firstName: string
  lastName: string
  email: string
  phone: string
  invitationAcceptedAt: string
  role: any // UserRole
  invitationSentAt: any

  constructor (data: IUser) {
    super(data)
    Object.assign(this, data)
  }

  get name () {
    return `${this.firstName} ${this.lastName}`
  }
}

export default User
