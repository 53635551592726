export interface ChartConfigurationData {
  label: string,
  interval: 'weekly' | 'monthly' | 'quarterly' | 'annually'
  dataGroups: Array<string>
  color?: string
  data: Array<{ value: number, group: string }>
}

export interface ChartConfigurationSettings {
  chartType: 'bar' | 'line' | 'pie'
  measurement: string
  data: ChartConfigurationData | ChartConfigurationData[]
}

import { DateTime } from 'luxon'
import { camelCase, isArray, sumBy } from 'lodash-es'
import { v4 as uuid } from 'uuid'

import { monthAbbreviations } from '@utils/datasets'

// TODO: Figure out how to make colors dynamic based on Tailwind config
const barChartColorsByIndex = [
  '#f4952c', // metric-orange
  '#00a7ce', // metric-teal
]

export default class ChartConfigurationService {
  public uuid: string

  private config: ChartConfigurationSettings

  constructor (config: ChartConfigurationSettings) {
    this.uuid = uuid()
    this.config = config
  }

  get chartType () {
    return this.config.chartType
  }

  get chartData () {
    return this.config.data
  }

  get hasMultipleDatasets () {
    return isArray(this.chartData)
  }

  get rawDataAsArray () {
    return [this.chartData].flat()
  }

  get dataGroups () {
    return this.rawDataAsArray[0].dataGroups
  }

  get dataInterval () {
    return this.rawDataAsArray[0].interval
  }

  getChartSettings () {
    return {
      labels: buildLabels(this.dataGroups, this.dataInterval),
      datasets: this.rawDataAsArray.map((chartData, idx) => {
        return {
          label: chartData.label,
          backgroundColor: chartData.color || barChartColorsByIndex[(idx + 1) % barChartColorsByIndex.length],
          borderColor: chartData.color || barChartColorsByIndex[(idx + 1) % barChartColorsByIndex.length],
          borderWidth: 2,
          // barThickness: 10,
          data: chartData.data.map((d:any) => d.count),
          // data: this.dataGroups.map(group => {
          //   return sumBy(chartData.data, 'count')
          // }),

          // trendlineLinear: {
          //   colorMin: 'green',
          //   colorMax: 'green',
          //   lineStyle: 'dotted',
          //   width: 2,
          //   // projection: true,
          // },
        }
      }),
    }
  }
}

function isInGroup (data: any, frequency: string, compare: string) {
  // TODO: Finish this
  if (frequency === 'weekly') {
    return data.group === compare
  } else if (frequency === 'monthly') {
    return DateTime.fromISO(data.group).month === DateTime.fromISO(compare).month
  } else if (frequency === 'quarterly') {
  } else if (frequency === 'annually') {

  }
}

function buildLabels (dataGroups: Array<string>, frequency: string) {
  if (frequency === 'weekly') {
    return buildLabelsForWeeklyData(dataGroups)
  } else if (frequency === 'monthly') {
    return buildLabelsForMonthlyData(dataGroups)
  } else if (frequency === 'quarterly') {
    return buildLabelsForQuarterlyData(dataGroups)
  } else if (frequency === 'annually') {
    return buildLabelsForYearlyData(dataGroups)
  } else {
    return []
  }
}

function buildLabelsForWeeklyData (dataGroups: Array<string>) {
  return dataGroups.map(g => DateTime.fromISO(g).toLocaleString(DateTime.DATE_SHORT))
}

function buildLabelsForMonthlyData (dataGroups: Array<string>) {
  return dataGroups.map(d => {
    const parsedDate = DateTime.fromISO(d)
    return `${monthAbbreviations[parsedDate.month - 1]}`
  })
}

function buildLabelsForQuarterlyData (dataGroups: Array<string>) {
  return dataGroups
}

function buildLabelsForYearlyData (dataGroups: Array<string>) {
  return dataGroups
}