import { DateTime } from 'luxon'
import type { IAttributes } from '@services/jsonapi'

class Announcement {
  NAME = 'Announcement'

  id: number
  title: string
  body: string
  bodyPlainText: string
  aasmState: string
  countReadReceipts: number
  countUnreadReceipts: number
  publishedAt: string
  publishedAt_lux: DateTime
  inactiveAt: string
  inactiveAt_lux: DateTime
  lastEditedAt: string
  lastEditedAt_lux: DateTime
  createdByUserId: number
  publishedByUserId: number
  inactiveByUserId: number
  lastEditedByUserId: number
  createdAt: string
  createdAt_lux: DateTime
  updatedAt: string
  updatedAt_lux: DateTime
  publishedByUserName?: string
  createdByUserName?: string
  groupedTargets: string

  constructor (data: IAttributes) {
    Object.assign(this, data)
    
    this.publishedAt_lux = DateTime.fromISO(this.publishedAt)
    this.inactiveAt_lux = DateTime.fromISO(this.inactiveAt)
    this.lastEditedAt_lux = DateTime.fromISO(this.lastEditedAt)
    this.createdAt_lux = DateTime.fromISO(this.createdAt)
    this.updatedAt_lux = DateTime.fromISO(this.updatedAt)
  }

  get isDraft () {
    return this.aasmState === 'draft'
  }

  get isPublished () {
    return this.aasmState === 'published'
  }

  get bodyPreview () {
    return this.body.split(' ').slice(0, 25).join(' ')
  }
}

export default Announcement