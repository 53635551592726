import axios from '@services/axios'
import Subregion from '@models/Subregion'
import type { ISubregion } from '@models/interfaces'

export interface IFetchSubregionsParams {
  regionId: number
}

export async function fetchSubregions (params: IFetchSubregionsParams) {
  const response = await axios.get(`/regions/${params.regionId}/subregions.json`)
  return response.data.map((c: ISubregion) => (new Subregion(c)))
}