import { groupBy, memoize } from "lodash-es";
import { DateTime } from "luxon";

/**
 * Memoize the `weekToReadableDateFn` function to minimize number of object conversions needed.
 */
export const weekToReadableDate = memoize(weekToReadableDateFn);

/**
 * Convert a week string into a DateTime object
 * @param week {string} Should follow "WW-yy" format, such as "18-23" symbolizing week number 18 in the year 2023.
 * @returns {DateTime}
 */
function weekToReadableDateFn(week: string): DateTime {
  const [ weekStr, shortYearStr ] = week.split('-');
  const weekNumber = parseInt(weekStr);
  const weekYear = DateTime.fromFormat(shortYearStr, 'yy').year

  const date = DateTime.fromObject({
    weekNumber,
    weekYear
  });

  return date;
}

export function datesToScheduledWeeks(dates: DateTime[]) {
  return dates
    .sort((a, b) => a > b ? 1 : -1)
    .map(week => week.toFormat('WW-yy'));
}
