import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  declare readonly foregroundTarget: HTMLElement
  declare readonly backgroundTarget: HTMLElement
  declare readonly valueTarget: HTMLInputElement


  static targets = ["foreground", "background", "value"]

  connect (): void {}

  toggle (evt: any): void {
    evt.preventDefault()

    if (this.foregroundTarget.classList.contains("translate-x-0")) {
      this.enable()
    } else {
      this.disable()
    }
  }

  enable (): void {
    this.backgroundTarget.classList.add("bg-blue-primary")
    this.backgroundTarget.classList.remove("bg-gray-200")

    this.foregroundTarget.classList.add("translate-x-5")
    this.foregroundTarget.classList.remove("translate-x-0")
    
    this.valueTarget.value = 'true'
  }

  disable (): void {
    this.backgroundTarget.classList.add("bg-gray-200")
    this.backgroundTarget.classList.remove("bg-blue-primary")

    this.foregroundTarget.classList.add("translate-x-0")
    this.foregroundTarget.classList.remove("translate-x-5")

    this.valueTarget.value = 'false'
  }
}