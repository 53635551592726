import { Controller } from '@hotwired/stimulus'

// TODO: Make this generic one day
export default class extends Controller {
  declare readonly containerTarget: HTMLElement
  declare readonly templateTarget: HTMLElement
  // declare readonly valueTarget: HTMLElement
  // declare readonly valueTarget: HTMLInputElement

  static targets = ["container", "template"]

  connect (): void {}

  duplicate (evt: any): void {
    evt.preventDefault()

    let newInput = document.createElement("input")
    newInput.name = "church_site[report_emails]"
    newInput.name = `church_site[report_emails][${this.containerTarget.children.length}]`
    newInput.type = "text"
    newInput.placeholder = "Email"

    newInput.classList.add("mb-2")
    newInput.classList.add("text-sm")
    newInput.classList.add("text-gray-text")
    newInput.classList.add("border")
    newInput.classList.add("border-gray-300")
    newInput.classList.add("rounded-md")
    
    this.containerTarget.appendChild(newInput)
  }
} 