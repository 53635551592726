export {}

interface DirectUploadEvent extends Event {
  target: HTMLInputElement,
  detail: {
    id: string,
    file: File,
    progress?: number,
    error?: string,
  },
}

document.addEventListener('direct-upload:initialize', event => {
  const { target, detail } = event as DirectUploadEvent
  const { id, file } = detail
  target.insertAdjacentHTML('beforebegin', `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  if (target && target.previousElementSibling) {
    const el = target.previousElementSibling.querySelector(`.direct-upload__filename`)
    if (el) {
      el.textContent = file.name
    }
  }
})

document.addEventListener('direct-upload:start', event => {
  const { detail: { id } } = event  as DirectUploadEvent
  const el = document.getElementById(`direct-upload-${id}`)
  if (el) {
    el.classList.remove('direct-upload--pending')
  }
})

document.addEventListener('direct-upload:progress', event => {
  const { detail: { id, progress } } = event as DirectUploadEvent
  const progressEl = document.getElementById(`direct-upload-progress-${id}`)
  if (progressEl) {
    progressEl.style.width = `${progress}%`
  }
})

document.addEventListener('direct-upload:error', event => {
  event.preventDefault()
  const { detail: { id, error } } = event as DirectUploadEvent
  const el = document.getElementById(`direct-upload-${id}`)
  if (el) {
    el.classList.add('direct-upload--error')
    el.setAttribute('title', error!)
  }
})

document.addEventListener('direct-upload:end', event => {
  const { detail: { id } } = event as DirectUploadEvent
  const el = document.getElementById(`direct-upload-${id}`)
  if (el) {
    el.classList.add('direct-upload--complete')
  }
})