import { DateTime } from 'luxon'
import type { IReportForm, IReportFormSection } from '@models/interfaces'
import ReportFormSection from '@models/ReportFormSection'

class ReportForm {
  id: number
  state: string
  publishedAt: string
  publishedAt_lux: DateTime | null
  inactiveAt: string
  inactiveAt_lux: DateTime | null
  lastEditedAt: string
  lastEditedAt_lux: DateTime | null

  regionId: number
  subregionId: number
  churchSiteId: number

  reportFormSections: ReportFormSection[]

  constructor (data: IReportForm) {
    Object.assign(this, data)
    this.publishedAt_lux = this.publishedAt ? DateTime.fromISO(this.publishedAt) : null
    this.inactiveAt_lux = this.inactiveAt ? DateTime.fromISO(this.inactiveAt) : null
    this.lastEditedAt_lux = this.lastEditedAt ? DateTime.fromISO(this.lastEditedAt) : null

    if (this.reportFormSections && this.reportFormSections.length > 0) {
      // TODO: Do something about this type casting message???
      this.reportFormSections = this.reportFormSections.map(d => new ReportFormSection(d as IReportFormSection))
    }
  }

  get serviceSections () {
    return this.reportFormSections.filter(s => s.isService)
  }
}

export default ReportForm