import { ref, watch } from 'vue'

import { subscribe, dispatch } from '@services/EventBus'
import { snakeCaseKeys } from '@utils'

import type { Ref } from 'vue'

export interface IGlobalFilter {
  [key: string]: any
}

interface IFilterRegistration { [key: string]: GlobalFilter }
const FILTERS: IFilterRegistration = {}

export function getFilterByKey (key: string) {
  if (!FILTERS[key]) {
    FILTERS[key] = new GlobalFilter(key)
  }
  return FILTERS[key]
}

class GlobalFilter {
  key: string
  filter: Ref<IGlobalFilter> = ref({})

  constructor (key: string) {
    this.key = key
    watch(this.filter, () => {
      dispatch('global-filter:updated', this)
    })
  }

  clearValue (key: string) {
    this.setValue(key, undefined)
  }

  setValue (key: string, value: any) {
    this.filter.value = { ...this.filter.value, [key]: value }
  }

  getValue (key: string) {
    return this.filter.value[key]
  }

  registerCallback (cb: Function) {
    return subscribe('global-filter:updated', (inst: GlobalFilter) => {
      if (inst.key === this.key) {
        cb(inst)
      }
    })
  }

  get snakeFilter () {
    return snakeCaseKeys(this.filter.value)
  }
}

export default GlobalFilter