
import axios from '@services/axios'
import { get } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { DirectUpload } from '@rails/activestorage'

import { toast } from '@services/ToastService'
import { formSyntaxToJson } from '@utils'

interface DirectUploadOptions {
  uploadUrl?: string,
  uploadKey?: string,
  initialUrl?: string,
  noDataMessage?: string,
  updateResponseResourceUrlPath?: string,
}

export default class DirectUploadHandler {
  private uuid: string = uuid()
  private el: HTMLElement
  private url: string
  private key: string
  private initialUrl: string | undefined
  private inputField: HTMLInputElement
  private noDataMessage: string
  private resourceUrl: string | undefined
  private updateResponseResourceUrlPath: string | undefined
  private removePosition: string | undefined
  private addPosition: string | undefined
  private loading: boolean = false
  private eventListeners: IIndexable = {}
  private canUpload: boolean
  private canRemove: boolean
  private initialHtml: string

  constructor (el: HTMLElement, opts: DirectUploadOptions = {}) {
    this.el = el
    this.url = (opts.uploadUrl || el.dataset.uploadUrl)!
    this.key = (opts.uploadKey || el.dataset.uploadKey)!
    this.initialUrl = opts.initialUrl || el.dataset.initialUrl
    this.noDataMessage = opts.noDataMessage || el.dataset.noDataMessage || 'No data'
    this.removePosition = el.dataset.removePosition
    this.addPosition = el.dataset.addPosition
    this.updateResponseResourceUrlPath = opts.updateResponseResourceUrlPath || el.dataset.updateResponseResourceUrlPath
    this.initialHtml = this.el.innerHTML
    this.canUpload = !!el.dataset.allowUpload
    this.canRemove = !!el.dataset.allowDeleteUpload
    this.initialize()
  }

  get isLoading () {
    return this.loading
  }

  initialize () {
    this.resourceUrl = this.initialUrl && this.initialUrl !== 'false'
      ? this.initialUrl
      : undefined

    const anchor = this.el.querySelector('a[data-resource-target]') as HTMLAnchorElement
    if (anchor) {
      anchor.href = this.resourceUrl || ''
    }

    if (!this.el.classList.contains('direct-upload--initialized')) {
      this._setupFormElements()
    }
    this._determineVisibleState()

    this.el.classList.add('direct-upload--initialized')
  }

  toggleLoadingState () {
    if (this.isLoading) {
      this.el.classList.remove('loading')
    } else {
      this.el.classList.add('loading')
    }
    this.loading = !this.loading
    this._determineVisibleState()
  }

  updateSubElements () {
    const anchor = this.el.querySelector('a[data-resource-target]') as HTMLAnchorElement
    if (anchor) {
      anchor.href = this.resourceUrl || ''
    }

    const img = this.el.querySelector('img[data-resource-target]') as HTMLImageElement
    if (img) {
      img.src = this.resourceUrl || ''
    }
  }

  reset () {
    this.el.innerHTML = this.initialHtml
  }

  _setupFormElements () {
    const loadingMessage = document.createElement('div')
    loadingMessage.classList.add('direct-upload--loading-message')
    loadingMessage.innerHTML = 'Loading...'
    this.el.appendChild(loadingMessage)

    const noDataDiv = document.createElement('div')
    noDataDiv.classList.add('direct-upload--no-data-message')
    noDataDiv.innerHTML = this.noDataMessage
    this.el.appendChild(noDataDiv)

    if (this.canUpload) {
      const input = document.createElement('input')
      input.classList.add('direct-upload--file-input')
      input.name = this.key
      input.type = 'file'
      input.dataset.directUploadUrl = '/rails/active_storage/direct_uploads'
      this.inputField = input
      this.el.appendChild(input)

      const trigger = document.createElement('button')
      trigger.classList.add(...[
        ...['direct-upload--upload-trigger'],
        ...(this.addPosition === 'top-right' && ['add-top-right'] || []),
        ...['flex', 'justify-center', 'items-center', 'border', 'text-sm', 'rounded-full', 'bg-gray-shaded-input', 'w-6', 'h-6', 'focus:outline-0', 'transition', 'duration-150', 'ease-in-out'],
        ...['border-transparent', 'text-gray-text', 'hover:text-blue-primary' ,'focus:outline-0'],
      ])
      trigger.innerHTML = '<svg class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" /></svg>'
      this.el.appendChild(trigger)

      this.eventListeners.uploadTriggered = trigger.addEventListener('click', evt => {
        input.click()
      })

      this.eventListeners.fileInputChanged = input.addEventListener('change', evt => {
        if (input.files && input.files.length > 1) {
          toast.error('You can only upload one file.')
        } else if (input.files) {
          this.toggleLoadingState()
          this._directUpload(input.files[0])
            .then(resp => {
              this.resourceUrl = get((resp as IIndexable).data, this.updateResponseResourceUrlPath!)
              this.updateSubElements()
              this.toggleLoadingState()
              toast.success('File Uploaded', 'File has been successfully uploaded.')
            })
            .catch(resp => {
              this.toggleLoadingState()
              toast.error('File Upload Failed', 'File has failed to upload.')
            })
        }
      })
    }

    if (this.canRemove) {
      const removeButton = document.createElement('button')
      removeButton.classList.add(...[
        ...['direct-upload--remove-trigger'],
        ...(this.removePosition === 'top-right' && ['remove-top-right'] || []),
        ...['flex', 'justify-center', 'items-center', 'border', 'text-sm', 'rounded-full', 'bg-gray-shaded-input', 'w-6', 'h-6', 'focus:outline-0', 'transition', 'duration-150', 'ease-in-out'],
        ...['border-transparent', 'text-gray-text', 'hover:text-red-500' ,'focus:outline-0'],
      ])
      removeButton.innerHTML = '<svg class="w-4 h-4" fill="none" stroke="currentColor" stroke-linecap="rounded" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"><path fill-rule="none" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" clip-rule="evenodd"></path></svg>'
      this.el.appendChild(removeButton)

      this.eventListeners.removeTriggered = removeButton.addEventListener('click', evt => {
        if (confirm('Are you sure?')) {
          this.toggleLoadingState()
          axios.put(this.url, formSyntaxToJson(this.key, null))
            .then(resp => {
              this.resourceUrl = undefined
              this.toggleLoadingState()
              toast.success('File has been successfully removed.', 'Success')
            })
            .catch(resp => {
              this.toggleLoadingState()
              toast.error('File has failed to be removed.', 'Failed')
            })
        }
      })
    }
  }

  _directUpload (file: File) {
    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
    return new Promise((resolve, reject) => {
      upload.create((err, blob) => {
        if (err) {
          reject(err)
        } else {
          axios.put(this.url, formSyntaxToJson(this.key, blob.signed_id))
            .then((resp) => {
              this.inputField.value = ''
              resolve(resp)
            })
            .catch((resp) => {
              this.inputField.value = ''
              reject(resp)
            })
        }
      })
    })
  }

  _determineVisibleState () {
    if (this.resourceUrl) {
      this.el.classList.remove('no-data')
    } else {
      this.el.classList.add('no-data')
    }
  }
}