/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

import Vue, { createApp } from 'vue'
import { Tabs, Tab } from 'vue3-tabs-component'
import VueClickAway from 'vue3-click-away'
import vfmPlugin from 'vue-final-modal'

import EventBus, { dispatch } from '@services/EventBus'
window.EventBus = EventBus

import 'trix'
import '@rails/actiontext'
import 'youtube'

import { Chart, registerables } from 'chart.js'
import chartTrendline from 'chartjs-plugin-trendline'
Chart.register(chartTrendline)
Chart.register(...registerables)

import '@utils/directUploads'
import { start as ActiveStorageStart } from '@rails/activestorage'
ActiveStorageStart()

function registerGlobalComponents (app: Vue.App<Element>) {
  const a = app
    .use(vfmPlugin)
    .use(VueClickAway)
    .component('tabs', Tabs)
    .component('tab', Tab)
  return a
}

// Import the Stimulus controllers
import './controllers'

// Import Tailwind styles and customs
import './application.css'
import 'tippy.js/dist/tippy.css'

const COMPONENT_MAP: Map<string, any> = new Map<string, any>()

import ReportForm from '@components/reportForm/ReportForm.vue'
COMPONENT_MAP.set('ReportForm', ReportForm)
import PercentageBar from '@components/charts/PercentageBar.vue'
COMPONENT_MAP.set('PercentageBar', PercentageBar)
import Histogram from '@components/charts/Histogram.vue'
COMPONENT_MAP.set('Histogram', Histogram)
import LineChart from '@components/charts/LineChart.vue'
COMPONENT_MAP.set('LineChart', LineChart)
import DoughnutChart from '@components/charts/DoughnutChart.vue'
COMPONENT_MAP.set('DoughnutChart', DoughnutChart)
import Tasks from '@components/tasks/Tasks.vue'
COMPONENT_MAP.set('Tasks', Tasks)
import Scorecard from '@components/scorecards/Scorecard.vue'
COMPONENT_MAP.set('Scorecard', Scorecard)
import Scorecards from '@components/scorecards/Scorecards.vue'
COMPONENT_MAP.set('Scorecards', Scorecards)
import AttendanceOverview from '@components/attendance/AttendanceOverview.vue'
COMPONENT_MAP.set('AttendanceOverview', AttendanceOverview)
import AttendanceReportPDF from '@components/attendance/AttendanceReportPDF.vue'
COMPONENT_MAP.set('AttendanceReportPDF', AttendanceReportPDF)
import GeneralStatisticsOverTime from '@components/generalStatisticsOverTime/GeneralStatisticsOverTime.vue'
COMPONENT_MAP.set('GeneralStatisticsOverTime', GeneralStatisticsOverTime)
import GeneralStatisticsCompared from '@components/generalStatisticsCompared/GeneralStatisticsCompared.vue'
COMPONENT_MAP.set('GeneralStatisticsCompared', GeneralStatisticsCompared)
import UserRoleTypeSelector from '@components/users/UserRoleTypeSelector.vue'
COMPONENT_MAP.set('UserRoleTypeSelector', UserRoleTypeSelector)
import InviteUser from '@components/inviteUser/InviteUser.vue'
COMPONENT_MAP.set('InviteUser', InviteUser)
import AnnouncementsIndex from '@components/announcements/AnnouncementsIndex.vue'
COMPONENT_MAP.set('AnnouncementsIndex', AnnouncementsIndex)
import RegionalInsightsFilter from '@components/regionalInsightsFilter/RegionalInsightsFilter.vue'
COMPONENT_MAP.set('RegionalInsightsFilter', RegionalInsightsFilter)
import ApiDashboard from '@components/apiDashboard/ApiDashboard.vue'
COMPONENT_MAP.set('ApiDashboard', ApiDashboard)
import AnnouncementTargetSelector from '@components/announcements/AnnouncementTargetSelector.vue'
COMPONENT_MAP.set('AnnouncementTargetSelector', AnnouncementTargetSelector)
import PasswordReset from '@components/passwordReset/PasswordReset.vue'
COMPONENT_MAP.set('PasswordReset', PasswordReset)
import UserManagement from '@components/users/UserManagement.vue'
COMPONENT_MAP.set('UserManagement', UserManagement)

const toInstantiate = document.querySelectorAll('[data-vue-component]')
if (toInstantiate.length > 0) {
  toInstantiate.forEach(el => {
    const elAttrs = el.attributes as IIndexable
    const comp = COMPONENT_MAP.get(elAttrs['data-vue-component'].value)
    const props = elAttrs['data-props']?.value
    if (comp) {
      registerGlobalComponents(
        createApp(comp, props && JSON.parse(props))
      ).mount(el)
    }
  })
}

import ModalsApp from '@components/ModalsApp.vue'
import ReportEntryValueEditModal from '@components/modals/ReportEntryValueEditModal.vue'
import AddChurchServiceModal from '@components/modals/AddChurchServiceModal.vue'
import EditScheduledServiceModal from '@components/modals/EditScheduledServiceModal.vue'
import InviteUserModal from '@components/modals/InviteUserModal.vue'
import AnnouncementModal from '@components/modals/AnnouncementModal.vue'
import AssigneeInfoModal from '@components/modals/AssigneeInfoModal.vue'
import FilterSettingModal from '@components/modals/FilterSettingModal.vue'
const modalEl = document.getElementById('modals-app')
if (modalEl) {
  registerGlobalComponents(createApp(ModalsApp))
    .component('ReportEntryValueEditModal', ReportEntryValueEditModal)
    .component('AddChurchServiceModal', AddChurchServiceModal)
    .component('EditScheduledServiceModal', EditScheduledServiceModal)
    .component('InviteUserModal', InviteUserModal)
    .component('AnnouncementModal', AnnouncementModal)
    .component('AssigneeInfoModal', AssigneeInfoModal)
    .component('FilterSettingModal', FilterSettingModal)
    .mount(modalEl)
}

import DirectUploadHandler from '@services/DirectUploadHandler'
const directUploadEls = document.querySelectorAll('.direct-upload-target')
if (directUploadEls.length > 0) {
  directUploadEls.forEach(el => {
    new DirectUploadHandler(el as HTMLElement)
  })
}

// Check for incoming flash messages and Toast them instead
import { toast } from '@services/ToastService'
if (window.flashMessages.notice) {
  toast.success('Success', window.flashMessages.notice)
}

if (window.flashMessages.alert) {
  toast.error('Error', window.flashMessages.alert)
}

// Create click handlers to open Announcement modals
import { isNumber } from 'lodash-es'
import axios from '@services/axios'
import Announcement from '@models/Announcement'
const announcementElems = Array.from(document.querySelectorAll('[data-preview-announcement]'))
if (announcementElems) {
  announcementElems.forEach(el => {
    el.addEventListener('click', async () => {
      let announcement = null
      const elAttrs = el.attributes as IIndexable

      const jsonData = JSON.parse(elAttrs['data-preview-announcement'].value)
      if (isNumber(jsonData)) {
        announcement = new Announcement((await axios.get(`/announcements/${elAttrs['data-preview-announcement'].value}`)).data)
      } else {
        announcement = new Announcement(JSON.parse(elAttrs['data-preview-announcement'].value))
      }

      dispatch('modal:spawn', {
        modal: 'AnnouncementModal',
        bind: { announcement: announcement },
      })
    })
  })
}