import User from '@models/User'
import { camelCaseKeys } from '@utils'

// import type Region from '@models/Region'
import type Subregion from '@models/Subregion'
import type Church from '@models/Church'
import type ChurchSite from '@models/ChurchSite'
// import type UserRole from '@models/UserRole'
import type { MetricSlugConfiguration } from '@utils/types'
// TODO: Create model for Church and ChurchSite and instantiate for `subject`
// TODO: Create model for UserRole and instantiate for `role`

const user = window.globals.current_user
const ctx = window.globals.application_context

interface IAppContext {
  environment: string
  currentUserId: number
  currentUser: User
  subjectId: number
  subjectType: string
  subject: any //Subregion | Church | ChurchSite
  roleLevel: string
  roleSubject: string
  role: any // UserRole
  viewingSingleSite: boolean
  viewingMultisite: boolean
  isChurchOwner: boolean
  isChurchAdmin: boolean
  isChurchAdminOrOwner: boolean
  isChurchReporter: boolean

  viewingRegion: boolean
  viewingSubregion: boolean
  viewingChurch: boolean
  viewingChurchSite: boolean

  regionId: number
  subregionId: number
  churchId: number
  churchSiteId: number

  development: boolean
  staging: boolean
  production: boolean

  roleLevelIsInChurch: boolean

  metricSlugs: {
    default: Array<MetricSlugConfiguration>
  }

  metricConfigs: Array<MetricSlugConfiguration>
}

export default user && ({
  environment: window.globals.ENVIRONMENT,
  currentUserId: user.id,
  currentUser: new User(user),
  subjectId: ctx.subject.id,
  subjectType: ctx.subject_type,
  subject: camelCaseKeys(ctx.subject),
  roleLevel: ctx.role.role_type,
  roleSubject: ctx.role.relatable_type,
  role: ctx.role,
  viewingSingleSite: window.globals.viewing_single_site,
  viewingMultisite: window.globals.viewing_multisite,
  numServices: ctx.num_services,
  isChurchOwner: ctx.role.role_type === 'church_owner',
  isChurchAdmin: ctx.role.role_type === 'church_admin',
  isChurchAdminOrOwner: ctx.role.role_type === 'church_admin' || ctx.role.role_type === 'church_owner',
  isChurchReporter: ctx.role.role_type === 'church_reporter',

  get development () {
    return this.environment === 'development'
  },

  get staging () {
    return this.environment === 'staging'
  },

  get production () {
    return this.environment === 'production'
  },

  get viewingRegion () {
    return ctx.subject_type === 'Region'
  },

  get regionId () {
    if (this.viewingRegion) {
      return this.subjectId
    } else if (this.viewingChurch || this.viewingChurchSite) {
      return this.subject.regionId
    } else {
      console.warn('Attempted to get RegionId when context is', this.subject)
      return 0
    }
  },

  get viewingSubregion () {
    return ctx.subject_type === 'Subregion'
  },

  get subregionId () {
    if (this.viewingSubregion) {
      return this.subjectId
    } else if (this.viewingChurch || this.viewingChurchSite) {
      return this.subject.subregionId
    } else {
      console.warn('Attempted to get SubregionId when context is', this.subject)
      return 0
    }
  },

  get viewingChurch () {
    return ctx.subject_type === 'Church'
  },

  get churchId () {
    if (this.viewingChurch) {
      return this.subjectId
    } else if (this.viewingChurchSite) {
      return this.subject.churchId
    } else {
      console.warn('Attempted to get ChurchId when context is', this.subject)
      return 0
    }
  },

  get viewingChurchSite () {
    return ctx.subject_type === 'ChurchSite'
  },

  get churchSiteId () {
    if (this.viewingChurchSite) {
      return this.subjectId
    } else if (this.viewingChurch) {
      console.warn('ChurchSiteId inaccessible when viewing Church context', this.subject)
      return 0
    } else {
      console.warn('Attempted to get ChurchSiteId when context is', this.subject)
      return 0
    }
  },

  get viewingChurchOrChurchSite () {
    return this.viewingChurch || this.viewingChurchSite
  },

  get roleLevelIsInChurch () {
    return ['church_owner', 'church_admin', 'church_reporter'].includes(this.roleLevel)
  },

  metricSlugs: {
    default: camelCaseKeys(window.globals.metric_slugs.default) as Array<MetricSlugConfiguration>,
  },

  metricConfigs: camelCaseKeys(window.globals.metrics) as Array<MetricSlugConfiguration>,
} as IAppContext)