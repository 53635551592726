import BaseModel from '@models/BaseModel'

import type { ISubregion } from '@models/interfaces'

class Subregion extends BaseModel {
  NAME = 'Subregion'

  id: number
  name: string
  regionId: number

  constructor (data: ISubregion) {
    super(data)
    Object.assign(this, data)
  }
}

export default Subregion