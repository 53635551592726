export const monthNames = [
  'January', 'February', 'March', 'April',
  'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December',
]

export const monthAbbreviations = [
  'Jan', 'Feb', 'Mar', 'Apr',
  'May', 'Jun', 'Jul', 'Aug',
  'Sep', 'Oct', 'Nov', 'Dec',
]

export const daysOfWeek = [
  { idx: 0, label: 'Sunday', value: 'sunday', abbrev: 'Sun' },
  { idx: 0, label: 'Monday', value: 'monday', abbrev: 'Mon' },
  { idx: 0, label: 'Tuesday', value: 'tuesday', abbrev: 'Tue' },
  { idx: 0, label: 'Wednesday', value: 'wednesday', abbrev: 'Wed' },
  { idx: 0, label: 'Thursday', value: 'thursday', abbrev: 'Thu' },
  { idx: 0, label: 'Friday', value: 'friday', abbrev: 'Fri' },
  { idx: 0, label: 'Saturday', value: 'saturday', abbrev: 'Sat' },
]