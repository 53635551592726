import { DateTime } from 'luxon'
import type { IAttributes } from '@services/jsonapi'

class AnnouncementReceipt {
  NAME = 'AnnouncementReceipt'

  id: number
  sentAt: string
  sentAt_lux: DateTime
  readAt: string
  readAt_lux: DateTime
  inactiveAt: string
  inactiveAt_lux: DateTime

  announcementTargetId: number
  announcementId: number
  sentToUserId: number
  sentByUserId: number

  constructor (data: IAttributes) {
    Object.assign(this, data)
    
    this.readAt_lux = DateTime.fromISO(this.readAt)
    this.sentAt_lux = DateTime.fromISO(this.sentAt)
    this.inactiveAt_lux = DateTime.fromISO(this.inactiveAt)
  }

  get isRead () {
    return !!this.readAt
  }
}

export default AnnouncementReceipt