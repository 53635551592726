import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['sidebarContainer']
  toggleTransition: (event?: Event) => void
  leave: (event?: Event) => void
  transitioned: false
  
  declare readonly sidebarContainerTarget: HTMLElement

  connect() {
    useTransition(this, {
      element: this.sidebarContainerTarget,
    })
  }

  toggle () {
    this.toggleTransition()
  }
}
