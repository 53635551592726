import BaseModel from '@models/BaseModel'

import type { IChurch } from '@models/interfaces'

class Church extends BaseModel {
  NAME = 'Church'

  id: number
  name: string
  isMultisite: boolean

  subregionId: number
  regionId: number

  constructor (data: IChurch) {
    super(data)
    Object.assign(this, data)
  }
}

export default Church