import axios from "axios";
import { instanceDefaults } from "./axios";
import type { YoutubeChannel, YoutubeChannelListResponse } from "./types/YoutubeTypes";

// Create new instance
const api = axios.create({
  baseURL: instanceDefaults.baseURL,
});

// Apply common headers without transformer
instanceDefaults.commonHeaders.forEach((commonHeader) => {
  api.defaults.headers.common[commonHeader.key] = commonHeader.value;
});

export function authenticate(churchId: string | number, code: string) {
  return api.post(`/churches/${churchId}/youtube/auth`, { code });
}

export function resetSession(churchId: string | number) {
  return api.delete(`/churches/${churchId}/youtube/auth`)
}

export function getChannels(churchId: string | number) {
  return api.get<YoutubeChannelListResponse>(
    `/churches/${churchId}/youtube/channels`
  );
}

export function selectChannel(churchId: string | number, channelId: string) {
  return api.put(`/churches/${churchId}/youtube/channels/select`, {
    channel_id: channelId,
  });
}

export function getMyChannel(churchId: string | number) {
  return api.get<{ data: { data: { channel: YoutubeChannel } } }>(`/churches/${churchId}/youtube/my_channel`);
}
