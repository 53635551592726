import axios from '@services/axios'
import Church from '@models/Church'
import type { IChurch } from '@models/interfaces'

export interface IFetchChurchesParams {
  regionId: number
}

export async function fetchChurches (params: IFetchChurchesParams) {
  const response = await axios.get(`/regions/${params.regionId}/churches.json`)
  return response.data.map((c: IChurch) => (new Church(c)))
}