import { DateTime } from 'luxon'
import { isNumber } from 'lodash-es'
import { postfixDateString } from '@utils'
import ReportEntryAssignee from '@models/ReportEntryAssignee'
import { formatInterval } from '@services/IntervalService'

import type { IAttributes } from '@services/jsonapi'
import type { IReportEntryAssignee } from '@models/interfaces'

class ReportEntry {
  id: number
  value: number
  metricSlug: string
  metricName: string
  metricDescription: string
  metricType: string
  reportedAt: string
  reportedAt_lux: DateTime | null
  reportedByUserId: number
  churchName: string
  churchSiteName: string
  sectionId: number
  sectionType: string
  sectionName: string
  intervalOpenedAt: string
  intervalOpenedAt_lux: DateTime
  intervalClosedAt: string
  intervalClosedAt_lux: DateTime
  interval: string
  dueDate: string
  dueDate_lux: DateTime

  reportEntryAssignee: ReportEntryAssignee

  constructor (data: IAttributes) {
    Object.assign(this, data)
    this.reportedAt_lux = this.reportedAt ? DateTime.fromISO(this.reportedAt) : null
    this.intervalOpenedAt_lux = DateTime.fromISO(this.intervalOpenedAt)
    this.intervalClosedAt_lux = DateTime.fromISO(this.intervalClosedAt)
    this.dueDate_lux = DateTime.fromISO(this.dueDate)

    if (this.reportEntryAssignee) {
      this.reportEntryAssignee = new ReportEntryAssignee(this.reportEntryAssignee as IReportEntryAssignee)
    }
  }

  get isOnService () {
    return this.sectionType === 'service'
  }

  get isOnGeneral () {
    return this.sectionType === 'general'
  }

  get intervalFormatted (): string {
    return formatInterval(this.interval, this.intervalOpenedAt_lux, this.intervalClosedAt_lux)
  }

  get dueFormatted (): string {
    return postfixDateString(this.dueDate_lux.toLocaleString({ month: 'long', day: 'numeric' }))
  }

  get reportedFormatted (): string | null {
    if (this.reportedAt_lux) {
      return postfixDateString(this.reportedAt_lux.toLocaleString({ month: 'long', day: 'numeric' }))
    }
    return null
  }

  get dueNow (): boolean {
    const now = DateTime.now()
    return now >= this.dueDate_lux && !this.completed
  }

  get upcoming (): boolean {
    const now = DateTime.now()
    return (this.intervalOpenedAt_lux && this.intervalClosedAt_lux > now) || !this.intervalOpenedAt_lux
  }

  get completed (): boolean {
    return isNumber(this.value) && !!this.reportedAt
  }

  get dueStatusColor (): string {
    const now = DateTime.now()
    if (this.dueNow) {
      return 'text-red-danger'
    } else if (this.intervalOpenedAt_lux <= now && now < this.intervalClosedAt_lux) {
      return 'text-green-success'
    } else {
      return 'text-gray-text'
    }
  }

  get intervalOpenedAtUnix () {
    return this.intervalOpenedAt_lux.toMillis()
  }

  get intervalClosedAtUnix () {
    return this.intervalClosedAt_lux.toMillis()
  }

  get isAssigned () {
    return !!this.reportEntryAssignee
  }

  get assigneeId () {
    return this.reportEntryAssignee?.assigneeId
  }

  get assigneeName () {
    return this.reportEntryAssignee?.name
  }
}

export default ReportEntry