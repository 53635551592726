import axios from '@services/axios'
import ReportFormSection from '@models/ReportFormSection'
import ReportFormMetric from '@models/ReportFormMetric'
import type { IReportFormMetric, IReportFormSection } from '@models/interfaces'

export interface IFetchReportFormSectionsParams {
  church_site_id?: number
  church_id?: number
}

export async function fetchReportFormSections (params: IFetchReportFormSectionsParams) {
  const response = await axios.get('/report_form_sections.json', { params })
  return response.data.map((s: IReportFormSection) => (new ReportFormSection(s)))
}

export async function fetchMetricsForReportFormSection (reportFormSectionId: number) {
  const response = await axios.get(`/report_form_sections/${reportFormSectionId}/metrics.json`)
  return response.data.map((s: IReportFormMetric) => (new ReportFormMetric(s)))
}