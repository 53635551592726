import { camelCase, cloneDeep, isArray, isNumber, isPlainObject, isString, map, mapKeys, mapValues, snakeCase } from 'lodash-es'

export function capitalize (str: string): string {
  return `${str[0].toUpperCase()}${str.slice(1)}`
}

export const groupBy = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function postfixDateString (dateString: string): string {
  let postfix = ''
  if (['11', '12', '13'].includes(dateString.slice(-2))) {
    postfix = 'th'
  } else if (['1'].includes(dateString.slice(-1))) {
    postfix = 'st'
  } else if (['2'].includes(dateString.slice(-1))) {
    postfix = 'nd'
  } else if (['3'].includes(dateString.slice(-1))) {
    postfix = 'rd'
  } else if (['4', '5', '6', '7', '8', '9', '0'].includes(dateString.slice(-1))) {
    postfix = 'th'
  }
  return `${dateString}${postfix}`
}

export function formatCurrency (num: number, includeCents = false): string {
  return (new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: includeCents ? 2 : 0,
    maximumFractionDigits: includeCents ? 2 : 0,
  })).format(num)
}

export function formatNumber (num: number): string {
  return (new Intl.NumberFormat('en-US')).format(num)
}

export function snakeCaseKeys (object: IIndexable): IIndexable {
  let snakeCaseObject = cloneDeep(object)

  if (isArray(snakeCaseObject)) {
    return map(snakeCaseObject, snakeCaseKeys)
  }
  if (isString(snakeCaseObject)) {
    return snakeCaseObject
  }
  snakeCaseObject = mapKeys(snakeCaseObject, (value, key) => snakeCase(key))

  // Recursively apply throughout object
  return mapValues(snakeCaseObject, (value) => {
    if (isPlainObject(value)) {
      return snakeCaseKeys(value)
    } else if (isArray(value)) {
      return map(value, snakeCaseKeys)
    }
    return value
  })
}

export function camelCaseKeys (object: IIndexable): IIndexable {
  let camelCaseObject = cloneDeep(object)

  if (isArray(camelCaseObject)) {
    return map(camelCaseObject, camelCaseKeys)
  }
  if (isString(camelCaseObject) || isNumber(camelCaseObject)) {
    return camelCaseObject
  }
  camelCaseObject = mapKeys(camelCaseObject, (value, key) => camelCase(key))

  // Recursively apply throughout object
  return mapValues(camelCaseObject, (value) => {
    if (isPlainObject(value)) {
      return camelCaseKeys(value)
    } else if (isArray(value)) {
      return map(value, camelCaseKeys)
    }
    return value
  })
}

export function correctIdDataTypes (object: IIndexable): IIndexable {
  let operationObject = object

  if (isArray(operationObject)) {
    return map(operationObject, correctIdDataTypes)
  }
  if (isString(operationObject) || isNumber(operationObject)) {
    return operationObject
  }
  operationObject = mapValues(operationObject, (value, key) => key === 'id' || key.endsWith('Id') ? parseInt(value) : value)

  // Recursively apply throughout object
  return mapValues(operationObject, (value) => {
    if (isPlainObject(value)) {
      return correctIdDataTypes(value)
    } else if (isArray(value)) {
      return map(value, correctIdDataTypes)
    }
    return value
  })
}

function assign(obj: IIndexable, keyPath: Array<string>, value: any) {
  const lastKeyIndex = keyPath.length - 1
  for (var i = 0; i < lastKeyIndex; ++i) {
    const key = keyPath[i]
    if (!(key in obj)) {
      obj[key] = {}
    }
    obj = obj[key]
  }
  obj[keyPath[lastKeyIndex]] = value
}

export function formSyntaxToJson (str: string, val: any) {
  const res = {}
  assign(res, str.split('[').map(e => e.replace(']', '')), val)
  return res
}

export function intervalToNoun (interval: string) {
  if (interval === 'weekly') {
    return 'week'
  } else if (interval === 'monthly') {
    return 'month'
  } else if (interval === 'quarterly') {
    return 'quarter'
  } else if (interval === 'annually') {
    return 'year'
  } else {
    return interval
  }
}