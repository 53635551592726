interface ToastOptions {
  id?: string,
  type: 'success' | 'warning' | 'error',
  title: string,
  content?: string,
  duration?: number,
  href?: string,
  onClick?: Function,
}

export interface ToastProps {
  id: string
  type: 'success' | 'warning' | 'error',
  destroy: () => void
  title: string,
  content?: string,
  duration?: number,
  href?: string,
  onClick?: Function,
}

import { createApp, ref } from 'vue'
import ToastApp from '@components/toasts/ToastApp.vue'

import type { Ref} from 'vue'

export class ToastManager {
  private containerRef: HTMLDivElement
  private toasts: Ref<ToastProps[]> = ref([])

  constructor () {
    const body = document.getElementsByTagName('body')[0] as HTMLBodyElement
    const toastContainer = document.createElement('div') as HTMLDivElement
    toastContainer.id = 'toast-container-main'
    body.insertAdjacentElement('beforeend', toastContainer)
    this.containerRef = toastContainer
    setTimeout(() => createApp(ToastApp).mount(this.containerRef), 0)
  }

  get activeToasts () {
    return this.toasts
  }

  public success (title: string, content?: string) {
    this.show({ type: 'success', title, content })
  }

  public warning (title: string, content?: string) {
    this.show({ type: 'warning', title, content })
  }

  public error (title: string, content?: string) {
    this.show({ type: 'error', title, content })
  }

  public show(options: ToastOptions): void {
    const toastId = Math.random().toString(36).slice(2, 2 + 9)
    const toast: ToastProps = {
      id: toastId,
      duration: 6000,
      ...options,
      destroy: () => this.destroy(options.id ?? toastId),
    }

    // Support many toasts
    // this.toasts.value = [toast, ...this.toasts.value]
    // OR
    // Support only one toast
    this.toasts.value = [toast]
  }

  public destroy(id: string): void {
    this.toasts.value = this.toasts.value.filter((toast: ToastProps) => toast.id !== id)
  }
}

export const toast = new ToastManager()