import type { IChurchSite } from '@models/interfaces'

class ChurchSite {
  NAME = 'ChurchSite'

  id: number
  name: string
  email: string
  phone: string
  hasYoutubeAuth: boolean
  youtubeChannel: string

  constructor (data: IChurchSite) {
    Object.assign(this, data)
  }
}

export default ChurchSite