import { DateTime } from 'luxon'

export function formatInterval (intervalType: string, open: DateTime, close: DateTime) {
  if (intervalType === 'weekly') {
    return formatWeeklyInterval(open, close)
  } else if (intervalType === 'monthly') {
    return formatMonthlyInterval(open, close)
  } else if (intervalType === 'quarterly') {
    return formatQuarterlyInterval(open, close)
  } else if (intervalType === 'annually') {
    return formatAnnuallyInterval(open, close)
  } else {
    return `${open.toLocaleString({ month: 'short', day: 'numeric' })} - ${close.toLocaleString({ day: 'numeric' })}`
  }
}

export function formatWeeklyInterval (open: DateTime, close: DateTime) {
  const now = DateTime.now()
  const showYear = close.year !== now.year
  if (open.month !== close.month) {
    return `${open.toLocaleString({ month: 'short', day: 'numeric' })} - ${close.toLocaleString({ month: 'short', day: 'numeric', ...showYear && { year: 'numeric' } })}`
  }

  let result = `${open.toLocaleString({ month: 'short', day: 'numeric' })} - ${close.toLocaleString({ day: 'numeric' })}`
  if (showYear) {
    result += `, ${close.toLocaleString({ year: 'numeric' })}`
  }
  return result
}

export function formatMonthlyInterval (open: DateTime, close: DateTime) {
  const now = DateTime.now()
  const showYear = close.year !== now.year

  // If the interval is for the entirety of one month exactly, short circuit to this format
  if (+open === +open.startOf('month') && +close === +open.endOf('month')) {
    return open.toLocaleString({ month: 'short', year: 'numeric' })
  }

  let result = `${open.toLocaleString({ month: 'short', day: 'numeric' })} - ${close.toLocaleString({ day: 'numeric' })}`
  if (showYear) {
    result += `, ${close.toLocaleString({ year: 'numeric' })}`
  }
  return result
}

export function formatQuarterlyInterval (open: DateTime, close: DateTime) {
  const now = DateTime.now()
  const showYear = close.year !== now.year
  return `${open.toLocaleString({ month: 'short' })} - ${close.toLocaleString({ month: 'short', ...showYear && { year: 'numeric' } })}`
}

export function formatAnnuallyInterval (open: DateTime, close: DateTime) {
  if (+open === +open.startOf('year') && +close === +close.endOf('year')) {
    return `${open.toLocaleString({ year: 'numeric' })}`
  } else {
    return `${open.toLocaleString({ month: 'short', year: 'numeric' })} - ${close.toLocaleString({ month: 'short', year: 'numeric' })}`
  }
}